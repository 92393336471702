<template>
  <div class="main-view">
    <div class="tableType">
      <el-radio-group v-model="table.params.status" @change="handleTabs">
        <el-radio-button label="">员工总数</el-radio-button>
        <el-radio-button :label="1">在职员工</el-radio-button>
        <el-radio-button :label="2">离职员工</el-radio-button>
      </el-radio-group>
    </div>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="用工单位" prop="company_name">
          <el-input clearable v-model="table.params.company_name" placeholder="请输入"></el-input>
          <!-- <el-select v-model="table.params.company_id" placeholder="请选择" clearable filterable>
            <el-option
              v-for="item in companyOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="员工姓名" prop="name">
          <el-input clearable v-model.trim="table.params.name" placeholder="请输入"></el-input>
        </el-form-item>
        <!--  只有总公司账号才有权限选择开票公司 -->
        <el-form-item v-if="this.icId === 0" label="开票单位" prop="invoice_company_id">
          <el-select v-model="table.params.invoice_company_id" placeholder="请选择" clearable filterable>
            <el-option
              v-for="item in invoiceCompanyOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入职时间" prop="entryDate">
          <el-date-picker
            v-model="table.params.entryDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="在职时间" prop="onDate">
          <el-date-picker
            v-model="table.params.onDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="table.params.status !==1" label="离职时间" prop="dimissionDate">
          <el-date-picker
            v-model="table.params.dimissionDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="创建时间" prop="createDate">
          <el-date-picker
            v-model="table.params.createDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询
          </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">创建</el-button>
        <template v-if="icId != 0">
          <el-button :size="subUnitSize" type="primary" plain @click="handleDownload">下载样表</el-button>
          <el-button :size="subUnitSize" type="primary" plain icon="el-icon-upload" @click="handleUpload(1)">批量导入</el-button>
          <el-button :size="subUnitSize" type="primary" plain icon="el-icon-upload" @click="handleUpload(2)">批量更新</el-button>
        </template>
        <el-button :size="subUnitSize" type="warning" plain icon="el-icon-download" @click="handleExport">全部导出</el-button>
        <el-button :size="subUnitSize" type="warning" plain icon="el-icon-download" @click="handleExportBatch">批量导出</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:sex="{row}">
        <span>{{row.sex === 1 ? '男' : '女'}}</span>
      </template>
      <!-- status 1:在职 2:离职 -->
      <template v-slot:status="{row}">
        <span>{{row.status === 1 ? '在职' : '离职'}}</span>
      </template>
      <template v-slot:company="{row}">
        <span v-if="!!row.company">{{row.company.name}}</span>
      </template>
      <template v-slot:dimission_at="{row}">
        <span>{{row.status === 2 ? row.dimission_at : ''}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click='showEdit(row)'>修改</el-button>
        <el-button type="text" @click="showDetail(row)">查看</el-button>
        <el-button type="text" @click="showDimission(row)" v-if="row.status !== 2">离职</el-button>
        <el-button type="text" @click="handleCome(row)" v-if="row.status == 2">恢复在职</el-button>

        <!-- <el-button type="text" @click='remove(row)' v-if="row.id !== 1 && !row.has_child">删除</el-button> -->
      </template>
    </VTable>

    <!-- 批量导入,批量更新 -->
    <el-dialog
      title="导入"
      :visible.sync="dialogVisible"
      :width="currType == 1 ? '1000px' : '600px'"
      :before-close="handleClose">
      <el-form
        ref='elFormDom'
        :model="upload"
        :rules="rulesForm"
        label-width="100px"
        :size="subUnitSize">
        <el-form-item label="用工单位" prop="company_id">
          <el-select v-model="upload.company_id" placeholder="请选择" clearable filterable>
            <el-option
              v-for="item in companyOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        
        <!-- 五险 -- 缴纳五险选了否 后面(缴纳档次、缴纳方式)就置灰不可选择-->
        <el-row v-if="currType == 1">
          <el-col :span="24">
            <el-row>
              <el-col :span="6">
                <el-form-item prop='is_insurance' label='缴纳五险：'>
                  <el-radio-group v-model="upload.is_insurance" @change="handleChangeRodio('insurance',$event)">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <template  v-if="upload.is_insurance === 1">
                <el-col :span="9">
                  <el-form-item prop='insurance_level_id' label='缴纳档次：'>
                    <el-select
                      v-model="upload.insurance_level_id"
                      placeholder="请选择"
                      :disabled="this.upload.is_insurance === 0 ? true : false">
                      <el-option
                        v-for="item in InsuranceLevelOpt"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item prop='insurance_pay_type' label='缴纳方式：'>
                    <el-select
                      v-model="upload.insurance_pay_type"
                      placeholder="请选择"
                      :disabled="this.upload.is_insurance === 0 ? true : false">
                      <el-option
                        v-for="item in payType"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </template>
            </el-row>
          </el-col>
          <!-- 公积金 -->
          <el-col :span="24">
            <el-row>
              <el-col :span="6">
                <el-form-item prop='is_fund' label='缴纳公积金：'>
                  <el-radio-group v-model="upload.is_fund" @change="handleChangeRodio('fund',$event)">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <template v-if="upload.is_fund === 1">
                <el-col :span="9">
                  <el-form-item prop='fund_level_id' label='缴纳比例：'>
                    <el-select
                      v-model="upload.fund_level_id"
                      placeholder="请选择"
                      :disabled="this.upload.is_fund === 0 ? true : false">
                      <el-option
                        v-for="item in fundLevelOpt"
                        :key="item.id"
                        :label="`${item.base}; ${item.company_rate}%,${item.employees_rate}%`"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item prop='fund_pay_type' label='缴纳方式：'>
                    <el-select
                      v-model="upload.fund_pay_type"
                      placeholder="请选择"
                      :disabled="this.upload.is_fund === 0 ? true : false">
                      <el-option
                        v-for="item in payType"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </template>
            </el-row>
          </el-col>
          <!-- 雇主责任险 -->
          <el-col :span="24">
            <el-row>
              <el-col :span="6">
                <el-form-item prop='is_duty' label='雇主责任险：'>
                  <el-radio-group v-model="upload.is_duty" @change="handleChangeRodio('duty', $event)">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <template v-if="upload.is_duty === 1">
                <el-col :span="9">
                  <el-form-item prop='company_duty_fee' label='单位部分：'>
                    <el-input
                      v-model='upload.company_duty_fee'
                      placeholder='请输入'
                      :disabled="this.upload.is_duty === 0 ? true : false">
                      <span slot="suffix">元</span>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item prop='person_duty_fee' label='员工部分：'>
                    <el-input
                      v-model='upload.person_duty_fee'
                      placeholder='请输入'
                      :disabled="this.upload.is_duty === 0 ? true : false">
                      <span slot="suffix">元</span>
                    </el-input>
                  </el-form-item>
                </el-col>
              </template>
            </el-row>
          </el-col>
        </el-row> 
      </el-form>

      <div style="display: flex;align-items: center;justify-content: center;">
        <el-upload
          class="upload-demo"
          drag
          :limit="1"
          action=""
          :headers="{token:token}"
          :file-list="fileList"
          :on-remove="removeFile"
          :multiple="false"
          :http-request="httpRequestBack"
          accept=".xls,.xlsx">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="uploadSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 导入成功弹窗 -->
    <el-dialog
      :title="currType == 1 ? '导入成功' : '更新成功'"
      :visible.sync="successDialogVisible"
      width="30%"
      center>
      <p style="text-align: center;">{{currType == 1 ? '已导入' : '已更新'}}{{templateDialog.result}}条</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="successClose">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 导入异常弹窗 -->
    <el-dialog
      :title="currType == 1 ? '导入异常' : '更新异常'"
      :visible.sync="errorDialogVisible"
      width="30%"
      center>
      <div style="text-align:center;">
        <el-button type="text" @click="innerVisible = true">查看异常<i class="el-icon-warning el-icon--right"></i></el-button>
      </div>
      <el-dialog
        width="30%"
        title="异常详情"
        :visible.sync="innerVisible"
        append-to-body
        center>
        <div v-if="templateDialog.errors.length > 0" class="errorBox">
          <p v-for="(item, index) in templateDialog.errors" :key="index">{{index +1}}、{{item}}</p>
        </div>
        <div v-else>
          无错误信息
        </div>
      </el-dialog>
      <span slot="footer" class="dialog-footer" v-if="currType == 1">
        <el-button @click="errorDialogVisible = false">取消上传</el-button>
        <el-button type="primary" @click="uploadSubmit2">继续上传</el-button>
      </span>
    </el-dialog>

    <edit ref="edit" @refresh="getTable"></edit>
    <detail ref="detail"></detail>
    <dimission ref="dimission" @refresh="getTable"></dimission>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import Detail from './components/Detail.vue'
import Dimission from './components/Dimission.vue'
import VTable from '@/components/VTable';
import {exportData} from "@/util/index"
import {getStorage} from '@/storage'
import { mapGetters } from 'vuex'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: '',
  mixins:[mixinTable],
  components: {
    Edit,
    Detail,
    Dimission,
    VTable,
  },
  computed: {
    ...mapGetters({
      invoiceCompanyOpt: 'invoiceCompany', // 获取开票单位
      companyOpt: 'companyList', // 获取企业列表
    })
  },
  data() {
    return {
      icId: Number(getStorage('icId')), // 登录账号的身份id（0：总公司）
      token: getStorage('token'),
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width:"60"},
        { name: "name", label: "员工姓名", hidden: false },
        { name: "sex", label: "性别", width:"60", hidden: false },
        { name: "phone", label: "联系电话", hidden: false},
        { name: "company", label: "用工单位", hidden: false},
        { name: "invoice_company_name", label: "开票单位", width:"220", hidden: false},
        { name: "created_at", label: "创建时间", hidden: false},
        { name: "entry_at", label: "入职时间", hidden: false},
        { name: "dimission_at", label: "离职时间", hidden: false},
        { name: "status", label: "状态", width:"60", hidden: false},
        { name: "action", label: "操作",width: "160", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          ids: [],
          name: '',
          // company_id: '',
          company_name: '',
          invoice_company_id: '',
          status: '',
          entryDate: [],
          onDate: [],
          createDate: [],
          dimissionDate: [],
          entry_at_min: null,
          entry_at_max: null,
          dimission_at_min: null,
          dimission_at_max: null,
          on_job_min: null,
          on_job_max: null,
          created_at_min: null,
          created_at_max: null,
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      managerFeeType: {
        1:'企业',
        2:'员工'
      },
      settlementType: {
        1:'当月薪资',
        2:'上月薪资'
      },
      rowId: '',
      isDetail: false,

      dialogVisible: false,
      fileList: [],
      upload: {
        company_id: '',
        is_insurance: 0, // 是否缴纳五险
        insurance_level_id: '',
        insurance_pay_type: '',
        is_fund: 0, // 是否缴纳公积金
        fund_level_id: '',
        fund_pay_type: '',
        is_duty: 0,  //是否缴纳雇主责任险
        company_duty_fee: '',
        person_duty_fee: '',
        file: '',
      },
      rulesForm: {
        company_id: [{ required: true, message:'必填项', trigger: 'change'}],
        insurance_level_id: [{ required: true, message:'必填项', trigger: 'change'}],
        insurance_pay_type: [{ required: true, message:'必填项', trigger: 'change'}],
        fund_level_id: [{ required: true, message:'必填项', trigger: 'change'}],
        fund_pay_type: [{ required: true, message:'必填项', trigger: 'change'}],
        company_duty_fee: [{ required: true, message:'必填项', trigger: 'change'}],
        person_duty_fee: [{ required: true, message:'必填项', trigger: 'change'}],
      },
      curfile: '',

      // 导入后提示
      successDialogVisible: false, // 导入成功弹窗
      errorDialogVisible: false,  // 导入异常弹窗
      templateDialog: {
        skip_errors: '',
        result: '',
        errors: [],
      },
      innerVisible: false,
      // 缴纳方式
      payType: [
        {
          value: 1,
          label: '单位全额'
        },
        {
          value: 2,
          label: '员工全额'
        },
        {
          value: 3,
          label: '单位员工各自缴纳'
        }
      ],
      InsuranceLevelOpt: [],
      fundLevelOpt: [],
      currType: 1, // 1:批量导入，2:批量更新
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      let _entryDate = this.table.params.entryDate;
      let _dimissionDate = this.table.params.dimissionDate;
      let _onDate = this.table.params.onDate;
      let _createDate = this.table.params.createDate;

      this.table.params.entry_at_min = _entryDate ? _entryDate[0] : ''
      this.table.params.entry_at_max = _entryDate ? _entryDate[1] : ''
      this.table.params.dimission_at_min = _dimissionDate ? _dimissionDate[0] : ''
      this.table.params.dimission_at_max = _dimissionDate ? _dimissionDate[1] : ''
      this.table.params.on_job_min = _onDate ? _onDate[0] : ''
      this.table.params.on_job_max = _onDate ? _onDate[1] : ''
      this.table.params.created_at_min = _createDate ? _createDate[0] : ''
      this.table.params.created_at_max = _createDate ? _createDate[1] : ''
      

      this.$http.post('/admin/employee/list', this.table.params).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            console.log('删除接口');
            done()
          } else {
            done()
          }
        }
      })
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row.id); // 传员工id
      dom = null
    },
    // 获取五险
    getInsurance() {
      this.$http.post('/admin/InsuranceLevel/list', {is_all: 1}).then(res => {
        if(res.code === 1) {
          this.InsuranceLevelOpt = res.data.list;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取公积金
    getFind() {
      this.$http.post('/admin/fundLevel/list', {is_all: 1}).then(res => {
        if(res.code === 1) {
          this.fundLevelOpt = res.data.list;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 全部导出
    handleExport() {
      if(this.table.data.length > 0) {
        let _entryDate = this.table.params.entryDate;
        let _dimissionDate = this.table.params.dimissionDate;
        let _onDate = this.table.params.onDate;
        let _createDate = this.table.params.createDate;
        let _param = {
          name: this.table.params.name,
          company_name: this.table.params.company_name,
          invoice_company_id: this.table.params.invoice_company_id,
          status: this.table.params.status,
          entry_at_min:  _entryDate.length > 1 ? _entryDate[0] : '',
          entry_at_max:  _entryDate.length > 1 ? _entryDate[1] : '',
          dimission_at_min: _dimissionDate.length > 1 ? _dimissionDate[0] : '',
          dimission_at_max: _dimissionDate.length > 1 ? _dimissionDate[1] : '',
          on_job_min: _onDate.length > 1 ? _onDate[0] : '',
          on_job_max: _onDate.length > 1 ? _onDate[1] : '',
          created_at_min: _createDate.length > 1 ? _createDate[0] : '',
          created_at_max: _createDate.length > 1 ? _createDate[1] : '',
        }
        console.log(_param);
        exportData(_param,'/admin/employee/export')
      } else {
        this.$message.warning('没有需要导出的数据！')
      }
    },
    // 批量导出
    handleExportBatch() {
      if(this.table.params.ids.length > 0) {
        let _entryDate = this.table.params.entryDate;
        let _dimissionDate = this.table.params.dimissionDate;
        let _onDate = this.table.params.onDate;
        let _createDate = this.table.params.createDate;
        let _param = {
          ids: this.table.params.ids.toString(),
          name: this.table.params.name,
          company_name: this.table.params.company_name,
          invoice_company_id: this.table.params.invoice_company_id,
          status: this.table.params.status,
          entry_at_min:  _entryDate.length > 1 ? _entryDate[0] : '',
          entry_at_max:  _entryDate.length > 1 ? _entryDate[1] : '',
          dimission_at_min: _dimissionDate.length > 1 ? _dimissionDate[0] : '',
          dimission_at_max: _dimissionDate.length > 1 ? _dimissionDate[1] : '',
          on_job_min: _onDate.length > 1 ? _onDate[0] : '',
          on_job_max: _onDate.length > 1 ? _onDate[1] : '',
          created_at_min: _createDate.length > 1 ? _createDate[0] : '',
          created_at_max: _createDate.length > 1 ? _createDate[1] : '',
        }
        console.log(_param);
        exportData(_param,'/admin/employee/export')
      } else  {
         this.$message.warning("请选择要批量导出的数据！");
      }
    },
    // 离职
    showDimission(row) {
      let dom = this.$refs.dimission
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 批量选择的数据
    handleSelectionChange(batchData) {
      this.table.params.ids = [];
      if(batchData.length > 0) {
        batchData.forEach(v => {
          this.table.params.ids.push(v.id)
        })
      }
    },
    // 下载样表
    handleDownload() {
      exportData({},'/admin/employee/downloadSample')
    },
    // 批量导入、批量更新弹窗
    handleUpload(type) {
      this.currType = type; // 1:批量导入，2:批量更新
      this.curfile = '';
      this.company_id = '';
      this.fileList = [];
      this.templateDialog.errors = [];
      this.$refs.elFormDom.resetFields();
      this.dialogVisible = true;
      this.getInsurance(); // 获取五险
      this.getFind(); // 获取公积金
    },
    httpRequestBack(file) {
      this.curfile = file.file
    },
    // 导入
    uploadSubmit() {
      this.$refs.elFormDom.validate(valid => {
        if (valid) {
          if(this.curfile) {
            this.templateDialog.skip_errors = 0; // 默认上传
            this.uploadRequer();
          } else {
            this.$message.warning(`请选择要${this.currType == 1 ? '导入' : '更新'}的文件`)
          }
        }
      })
    },
    // 继续导入
    uploadSubmit2() {
      this.templateDialog.skip_errors = 1; // 继续上传
       this.uploadRequer();
    },
    // 批量导入，批量更新请求
    uploadRequer() {
      let formData = new FormData();
      formData.append('file', this.curfile);
      formData.append('company_id', this.upload.company_id)
      

      let _params = {}
      if(this.currType == 1) {
        formData.append('skip_errors', this.templateDialog.skip_errors)
        formData.append('is_insurance', this.upload.is_insurance)
        formData.append('insurance_level_id', this.upload.insurance_level_id)
        formData.append('insurance_pay_type', this.upload.insurance_pay_type)
        formData.append('is_fund', this.upload.is_fund)
        formData.append('fund_level_id', this.upload.fund_level_id)
        formData.append('fund_pay_type', this.upload.fund_pay_type)
        formData.append('is_duty', this.upload.is_duty)
        formData.append('company_duty_fee', this.upload.company_duty_fee)
        formData.append('person_duty_fee', this.upload.person_duty_fee)

        _params = {
          company_id:this.upload.company_id,
          is_insurance: this.upload.is_insurance,
          insurance_level_id: this.upload.insurance_level_id,
          insurance_pay_type: this.upload.insurance_pay_type,
          is_fund: this.upload.is_fund,
          fund_level_id: this.upload.fund_level_id,
          fund_pay_type: this.upload.fund_pay_type,
          is_duty: this.upload.is_duty,
          company_duty_fee: this.upload.company_duty_fee,
          person_duty_fee: this.upload.person_duty_fee,
          skip_errors: this.templateDialog.skip_errors,
        }
      } else if(this.currType == 2) {
        _params = {
          company_id:this.upload.company_id,
        }
      }
      console.log(_params)
      let apiUrl = this.currType == 1 ? '/admin/employee/import' : '/admin/employee/importUser'
      this.$http.post(apiUrl, {formData, type:'upload',obj:_params}).then(res => {
        if(res.code === 1) {
          this.successDialogVisible = true;
          this.templateDialog.result = res.data.result;
        } else {
          this.errorDialogVisible = true;
          this.templateDialog.errors = res.data.errors;
        }
      }).finally(() => {
        this.dialogVisible = false;
      })
    },
    // 关闭成功的弹窗
    successClose() {
      this.successDialogVisible = false;
      this.errorDialogVisible = false;
      this.getTable();
    },
    handleClose() {
      this.dialogVisible = false
    },
    removeFile() {
      this.fileList = [];
    },
    handleChangeRodio(type, event) {
      if(type === 'insurance') {
        this.upload.insurance_level_id = ''
        this.upload.insurance_pay_type = ''
      }
      if(type === 'fund') {
        this.upload.fund_level_id = ''
        this.upload.fund_pay_type = ''
      }
      if(type === 'duty') {
        this.upload.company_duty_fee = ''
        this.upload.person_duty_fee = ''
      }
    },
    // 恢复在职
    handleCome(row) {
      this.$confirm('请确认恢复在职操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/admin/employee/entry', {id:row.id}).then(res => {
          if(res.code == 1) {
            this.$message.success('操作成功！')
            this.getTable();
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {});
    }
  }
}
</script>