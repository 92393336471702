<template>
  <div class="addPerson__wrap" v-show="visible">
    <el-button class="backBtn" plain icon="el-icon-d-arrow-left" @click="toggle(false)">返回</el-button>
    <div class="main">
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='120px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <!-- 员工信息 -->
        <el-divider content-position="left">员工信息</el-divider>
        <el-row class="rowbg"  type="flex" style="flex-wrap:wrap">
          <el-col :span="form.data.status === 2 ? 8 : 8">
            <el-form-item prop='name' label='员工名称：'>
              <el-input
                v-model.trim='form.data.name'
                @blur="getUserInfo"
                clearable
                placeholder='请输入员工名称'
                :disabled="this.isChange == true && this.role_type != 1 ? true : false">
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop='id_card_no' label='身份证号'>
              <el-input
                v-model.trim='form.data.id_card_no'
                @blur="getUserInfo"
                maxlength="18"
                clearable
                placeholder='请输入身份证号'
                :disabled="this.isChange == true && (this.icId != 0 || this.role_type != 1) ? true : false"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="form.data.status === 2 ? 8 : 8">
            <el-form-item prop='phone' label='联系电话：'>
              <el-input
                v-model='form.data.phone'
                maxlength="11"
                clearable
                placeholder='请输入联系电话'
                :disabled="this.isChange == true && (this.icId != 0 || this.role_type != 1) ? true : false"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="form.data.status === 2 ? 8 : 8">
            <el-form-item prop='sex' label='性别：'>
              <el-radio-group v-model="form.data.sex" :disabled="this.isChange == true && (this.icId != 0 || this.role_type != 1) ? true : false">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- :disabled="this.isChange == true && (this.icId != 0 || this.role_type != 1) ? true : false" -->
          <el-col :span="8">
            <el-form-item prop='entry_at' label='入职时间：'>
              <el-date-picker
                v-model="form.data.entry_at"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                :disabled="this.isChange == true && this.role_type != 1 ? true : false">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="form.data.status === 2">
            <el-form-item prop='dimission_at' label='离职时间：'>
              <el-date-picker
                v-model="form.data.dimission_at"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                :disabled="this.isChange == true && (this.icId != 0 || this.role_type != 1) ? true : false"
                :picker-options="{
                  disabledDate(time) {
                    return time.getTime() < new Date(form.data.entry_at).getTime()
                  }
                }">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop='company_id' label='用工单位'>
              <el-select v-model="form.data.company_id" placeholder="请选择" filterable @change="handleCompanyChange" :disabled="this.isChange == true && (this.icId != 0 || this.role_type != 1) ? true : false">
                <el-option
                  v-for="item in companyOpt"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- this.isChange == true && (this.icId != 0 || this.role_type != 1) ? true : false" (之前需求是超级管理员才能改基本工资)-->
          <el-col :span="8">
            <el-form-item prop='salary' label='基本工资：'>
              <el-input
                v-model='form.data.salary'
                placeholder='请输入基本工资'
                :disabled="this.isChange == true && this.role_type != 1 ? true : false">
                <span slot="suffix">元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <!-- 只有离职状态显示 -->
          <el-col :span="8" v-if="form.data.status == 2">
            <el-form-item prop="dimission_pay_type" label="当月薪资：">
              <el-radio-group v-model="form.data.dimission_pay_type">
                <el-radio :label="1">正常发放</el-radio>
                <el-radio :label="2">无需发放</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- 五险 -- 缴纳五险选了否 后面(缴纳档次、缴纳方式)就置灰不可选择-->
          <el-col :span="24">
            <el-row>
              <el-col :span="6">
                <el-form-item prop='is_insurance' label='缴纳五险：'>
                  <el-radio-group v-model="form.data.is_insurance" @change="handleChangeRodio('insurance',$event)">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <template  v-if="form.data.is_insurance === 1">
                <el-col :span="8">
                  <el-form-item prop='insurance_level_id' label='缴纳档次：'>
                    <el-select
                      v-model="form.data.insurance_level_id"
                      placeholder="请选择"
                      :disabled="this.form.data.is_insurance === 0 ? true : false">
                      <el-option
                        v-for="item in InsuranceLevelOpt"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item prop='insurance_pay_type' label='缴纳方式：'>
                    <el-select
                      v-model="form.data.insurance_pay_type"
                      placeholder="请选择"
                      :disabled="this.form.data.is_insurance === 0 ? true : false">
                      <el-option
                        v-for="item in payType"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </template>
            </el-row>
          </el-col>
          <!-- 公积金 -->
          <el-col :span="24">
            <el-row>
              <el-col :span="6">
                <el-form-item prop='is_fund' label='缴纳公积金：'>
                  <el-radio-group v-model="form.data.is_fund" @change="handleChangeRodio('fund',$event)">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <template v-if="form.data.is_fund === 1">
                <el-col :span="8">
                  <el-form-item prop='fund_level_id' label='缴纳比例：'>
                    <el-select
                      v-model="form.data.fund_level_id"
                      placeholder="请选择"
                      :disabled="this.form.data.is_fund === 0 ? true : false">
                      <el-option
                        v-for="item in fundLevelOpt"
                        :key="item.id"
                        :label="`${item.base}; ${item.company_rate}%,${item.employees_rate}%`"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item prop='fund_pay_type' label='缴纳方式：'>
                    <el-select
                      v-model="form.data.fund_pay_type"
                      placeholder="请选择"
                      :disabled="this.form.data.is_fund === 0 ? true : false">
                      <el-option
                        v-for="item in payType"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </template>
            </el-row>
          </el-col>
          <!-- 雇主责任险 -->
          <el-col :span="24">
            <el-row>
              <el-col :span="6">
                <el-form-item prop='is_duty' label='雇主责任险：'>
                  <el-radio-group v-model="form.data.is_duty" @change="handleChangeRodio('duty', $event)">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <template v-if="form.data.is_duty === 1">
                <el-col :span="8">
                  <el-form-item prop='company_duty_fee' label='单位部分：'>
                    <el-input
                      v-model='form.data.company_duty_fee'
                      placeholder='请输入'
                      :disabled="this.form.data.is_duty === 0 ? true : false">
                      <span slot="suffix">元</span>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item prop='person_duty_fee' label='员工部分：'>
                    <el-input
                      v-model='form.data.person_duty_fee'
                      placeholder='请输入'
                      :disabled="this.form.data.is_duty === 0 ? true : false">
                      <span slot="suffix">元</span>
                    </el-input>
                  </el-form-item>
                </el-col>
              </template>
            </el-row>
          </el-col>
        </el-row> 
        <!-- "is_sign_contract", 是否签署合同：0-未签署；1-已签署；若已签署则不展示岗位及合同信息 -->
        <el-row class="rowbg" v-if="form.data.is_sign_contract == 0"  type="flex" style="flex-wrap:wrap">
          <el-col :span="8">
            <el-form-item prop="company_post_id" label="所在岗位">
              <el-select v-model="form.data.company_post_id" clearable>
                <el-option v-for="item in jobsArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 如果选了岗位 合同信息必填 -->
          <template v-if="!!form.data.company_post_id">
            <el-col :span="8">
              <el-form-item prop="contract_type" label="合同类型">
                <el-select v-model="form.data.contract_type">
                  <el-option label="劳动合同" :value="1"></el-option>
                  <el-option label="劳务合同" :value="2"></el-option>
                  <el-option label="实习协议" :value="3"></el-option>
                  <el-option label="兼职协议" :value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- 只有选劳动合同才有期限类型这个字段 -->
            <el-col :span="8" v-if="form.data.contract_type == 1">
              <el-form-item prop="contract_date_type" label="期限类型">
                <el-select v-model="form.data.contract_date_type">
                  <el-option label="固定期限" :value="1"></el-option>
                  <el-option label="无固定期限" :value="2"></el-option>
                  <el-option label="完成一定工作量" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- 选了劳动或劳务合同才有试用期限 -->
            <el-col :span="8" v-if="form.data.contract_type == 1 || form.data.contract_type == 2">
              <el-form-item prop="trial_day" label="试用期限">
                  <el-date-picker
                    v-model="form.data.trial_day"
                    class="width100"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd">
                  </el-date-picker>
              </el-form-item>
            </el-col>
            <!-- 兼职协议才有正式工作单位 -->
            <el-col :span="8" v-if="form.data.contract_type == 4">
              <el-form-item prop="regular_job" label="正式工作单位">
                <el-input
                  v-model='form.data.regular_job'
                  placeholder='请输入'
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="contract_day" label="合同期限">
                <el-date-picker
                  v-model="form.data.contract_day"
                  class="width100"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </template>
        </el-row>
        <!-- 银行卡信息 -->
        <el-divider content-position="left">银行卡信息</el-divider>
        <el-row  class="rowbg" type="flex" style="flex-wrap:wrap">
          <el-col :span="24">
            <el-form-item prop='isChecked' label='银行卡信息'>
              <el-checkbox v-model="isChecked">{{isChecked === true ? '有银行卡' : '无银行卡'}}</el-checkbox>
            </el-form-item>
          </el-col>
          <template v-if="this.isChecked === true">
            <el-col :span="8">
              <el-form-item prop='bank_card_no' label='银行卡号' width="260">
                <el-input
                  v-model.trim='form.data.bank_card_no'
                  clearable
                  placeholder='请输入银行卡号'
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- 新增时开户行判断 -->
            <el-col :span="8" >
              <template v-if="limit_banks.length > 0">
                <el-form-item prop='bank_name' label='开户银行'>
                  <el-select v-model="form.data.bank_name">
                    <el-option v-for="item in limit_banks" :label="item.name" :value="item.name" :key="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </template>
              <template v-else>
                <el-form-item prop='bank_name' label='开户银行'>
                  <el-input
                    v-model.trim='form.data.bank_name'
                    clearable
                    :readonly="form.data.limit_bank_name != '' ? true : false"
                    placeholder='请输入开户银行'
                  ></el-input>
                </el-form-item>
              </template>
            </el-col>
            <!-- 编辑时开户行判断 -->
            <!-- <el-col :span="8" v-else>
              <template v-if="limit_banks.length > 1">
                <el-form-item prop='bank_name' label='开户银行'>
                  <el-select v-model="form.data.bank_name" >
                    <el-option v-for="item in limit_banks" :label="item.name" :value="item.id" :key="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </template>
              <template v-else>
                <el-form-item prop='bank_name' label='开户银行'>
                  <el-input
                    v-model.trim='form.data.bank_name'
                    clearable
                    :disabled="isbankShow"
                    placeholder='请输入开户银行'
                  ></el-input>
                </el-form-item>
              </template>
            </el-col> -->
            <el-col :span="8">
              <el-form-item prop='bank_card_name' label='持卡人姓名'>
                <el-input
                  v-model.trim='form.data.bank_card_name'
                  clearable
                  placeholder='请输入持卡人姓名'
                ></el-input>
              </el-form-item>
            </el-col>
          </template>
          <el-col :span="24">
            <el-form-item prop='mark' label='备注'>
              <el-input
                v-model='form.data.mark'
                type="textarea"
                :rows="2"
                clearable
                placeholder='请输入备注'
                maxlength="200"
               show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 个人信息 -->
        <el-divider content-position="left">个人信息</el-divider>
        <el-row class="rowbg" type="flex" style="flex-wrap:wrap">
          <el-col :span="24">
            <el-form-item label="身份证" class="idPhoto">
              <el-row>
                <el-col :sm="12">
                  <el-form-item prop="id_card_portrait_face">
                    <el-upload
                      class="avatar-uploader"
                      action=""
                      :headers="{ token: token }"
                      :show-file-list="false"
                      :before-upload="beforeAvatarUpload"
                      :http-request="httpRequestFront"
                      accept="image/jpeg, image/png, image/gif"
                      >
                      <img v-if="this.form.data.id_card_portrait_face" :src="this.form.data.id_card_portrait_face" class="avatar" style="object-fit: cover;">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      <div class="el-upload__tip" slot="tip">
                        身份证(人脸面), 要求清晰
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-col>
                <el-col :sm="12">
                  <el-form-item prop="id_card_emblem_face">
                    <el-upload
                      class="avatar-uploader"
                      action=""
                      :headers="{ token: token }"
                      :show-file-list="false"
                      :before-upload="beforeAvatarUpload"
                      :http-request="httpRequestBack"
                      accept="image/jpeg, image/png, image/gif"
                      >
                      <img v-if="this.form.data.id_card_emblem_face" :src="this.form.data.id_card_emblem_face" class="avatar"  style="object-fit: contain;">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      <div class="el-upload__tip" slot="tip">
                        身份证(国徽面), 要求清晰
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10" class="rowbg"  type="flex" style="flex-wrap:wrap">
          <el-col :span="8">
            <el-form-item prop='address' label='户籍地址'>
              <el-input
                v-model.trim='form.data.address'
                clearable
                placeholder='请输入户籍地址'
              ></el-input>
              <!-- :disabled="this.isChange == true && (this.icId != 0 || this.role_type != 1) ? true : false" -->
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop='residence' label='现居住地'>
              <el-input
                v-model.trim='form.data.residence'
                clearable
                placeholder='请输入'
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop='email' label='电子邮箱'>
              <el-input
                v-model.trim='form.data.email'
                clearable
                placeholder='请输入'
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop='emergency_contact' label='紧急联系人'>
              <el-input
                v-model.trim='form.data.emergency_contact'
                clearable
                placeholder='请输入'
                maxlength="11"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop='emergency_contact_phone' label='联系人电话'>
              <el-input
                v-model.trim='form.data.emergency_contact_phone'
                clearable
                placeholder='请输入'
                maxlength="11"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop='emergency_contact_relation' label='关系'>
              <el-input
                v-model.trim='form.data.emergency_contact_relation'
                clearable
                placeholder='请输入'
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop='nation' label='民族'>
              <el-input
                v-model.trim='form.data.nation'
                clearable
                placeholder='请输入'
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop='political_outlook' label='政治面貌'>
              <el-select v-model="form.data.political_outlook" placeholder="请选择">
                <el-option label="群众" :value="1"></el-option>
                <el-option label="团员" :value="2"></el-option>
                <el-option label="党员" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop='marital_status' label='婚姻状况'>
              <el-select v-model="form.data.marital_status" placeholder="请选择">
                <el-option label="未婚" :value="1"></el-option>
                <el-option label="已婚" :value="2"></el-option>
                <el-option label="离异" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 教育信息 -->
        <el-divider content-position="left"><span class="divider-title">教育信息</span><span style="font-size:12px;color:#409EFF;">（从最高学历开始填写）</span></el-divider>
        <el-row v-for="(item1,index1) in educations" :key="'1-'+index1"  type="flex" style="flex-wrap:wrap">
          <el-col :span="22">
            <el-row>
              <el-col :span="12">
                <el-form-item prop='educations_date' label='起止时间'>
                  <el-date-picker
                    v-model="item1.educations_date"
                    class="width100"
                    type="monthrange"
                    range-separator="至"
                    start-placeholder="开始月份"
                    end-placeholder="结束月份"
                    value-format="yyyy-MM">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop='school' label='学校/培训经历'>
                  <el-input v-model="item1.school" placeholder="请输入" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop='content' label='专业/培训内容'>
                  <el-input v-model="item1.content" placeholder="请输入" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop='licence' label='取得学历/证书'>
                  <el-input v-model="item1.licence" placeholder="请输入" clearable></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="2" class="icon-btn">
            <el-button v-if="educations.length == 2" type="danger" icon="el-icon-delete" circle size="mini" @click="educationsDel(index1)"></el-button>
            <el-button v-if="educations.length == 1" type="primary" icon="el-icon-plus" circle size="mini" @click="educationsPlus"></el-button>
          </el-col>
        </el-row>
        
        <!-- 工作经历 -->
        <el-divider content-position="left"><span class="divider-title">工作经历</span><span style="font-size:12px;color:#409EFF;">（从第一份工作开始填写）</span></el-divider>
        <el-row v-for="(item2,index2) in jobs" :key="'2-'+index2"  type="flex" style="flex-wrap:wrap">
          <el-col :span="22">
            <el-row>
              <el-col :span="8">
                <el-form-item prop='jobs_date' label='起止时间'>
              <el-date-picker
                v-model="item2.jobs_date"
                class="width100"
                type="monthrange"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
                value-format="yyyy-MM">
              </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
            <el-form-item prop='company' label='工作单位'>
              <el-input v-model="item2.company" placeholder="请输入" clearable></el-input>
            </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop='job' label='工作岗位'>
              <el-input v-model="item2.job" placeholder="请输入" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
            <el-form-item prop='salary' label='薪资水平'>
              <el-input v-model="item2.salary" placeholder="请输入" clearable></el-input>
            </el-form-item>
              </el-col>
              <el-col :span="8">
            <el-form-item prop='reason' label='离职原因'>
              <el-input v-model="item2.reason" placeholder="请输入" clearable></el-input>
            </el-form-item>
              </el-col>
              <el-col :span="8">
            <el-form-item prop='contact' label='证明人/电话'>
              <el-input v-model="item2.contact" placeholder="请输入" maxlength="11" clearable></el-input>
            </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="2" class="icon-btn">
            <el-button v-if="jobs.length == 2" type="danger" icon="el-icon-delete" circle size="mini" @click="jobsDel(index2)"></el-button>
            <el-button v-if="jobs.length == 1" type="primary" icon="el-icon-plus" circle size="mini" @click="jobsPlus"></el-button>
          </el-col>
        </el-row>
        <!-- 家庭信息 -->
        <el-divider content-position="left">家庭信息<span style="font-size:12px;color:#409EFF;">（父母、配偶、子女、兄弟姐妹）</span></el-divider>
        <el-row v-for="(item3,index3) in members" :key="index3"  type="flex" style="flex-wrap:wrap">
          <el-col :span="8">
            <el-form-item prop='' label='姓名'>
              <el-input v-model="item3.name" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop='' label='与本人关系'>
              <el-input v-model="item3.relation" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop='' label='年龄'>
              <el-input v-model="item3.age" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop='' label='现居地址/工作单位'>
              <el-input v-model="item3.address" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop='' label='电话'>
              <el-input v-model="item3.phone" placeholder="请输入" maxlength="11" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 是否有亲属在公司 -->
        <el-row  type="flex" style="flex-wrap:wrap">
          <el-col :span="24">
            <el-form-item prop='has_relative' label='是否有亲属在公司'>
              <el-radio-group v-model="form.data.has_relative">
                <el-radio :label="0">无</el-radio>
                <el-radio :label="1">有</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- 如果选了是 要出现 姓名、与本人关系 、职务三个字段 -->
          <template v-if="form.data.has_relative == 1">
            <el-col :span="8">
              <el-form-item prop='relative_name' label='姓名'>
                <el-input v-model="form.data.relative_name" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop='relative_relation' label='本人关系'>
                <el-input v-model="form.data.relative_relation" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop='relative_title' label='职务'>
                <el-input v-model="form.data.relative_title" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
          </template>
        </el-row>
        <!-- 福利保险 -->
        <el-divider content-position="left">福利保险</el-divider>
        <el-row  type="flex" style="flex-wrap:wrap">
          <el-col :span="8">
            <el-form-item prop="has_endowment" label="养老保险">
              <el-radio-group v-model="form.data.has_endowment">
                <el-radio :label="1">有</el-radio>
                <el-radio :label="0">无</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="has_medical" label="医疗保险">
              <el-radio-group v-model="form.data.has_medical">
                <el-radio :label="1">有</el-radio>
                <el-radio :label="0">无</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="has_unemployment" label="失业保险">
              <el-radio-group v-model="form.data.has_unemployment">
                <el-radio :label="1">有</el-radio>
                <el-radio :label="0">无</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="has_birth" label="生育保险">
              <el-radio-group v-model="form.data.has_birth">
                <el-radio :label="1">有</el-radio>
                <el-radio :label="0">无</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="has_injury" label="工伤保险">
              <el-radio-group v-model="form.data.has_injury">
                <el-radio :label="1">有</el-radio>
                <el-radio :label="0">无</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="has_fund" label="住房公积金">
              <el-radio-group v-model="form.data.has_fund">
                <el-radio :label="1">有</el-radio>
                <el-radio :label="0">无</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row  type="flex" style="flex-wrap:wrap">
          <el-col :span="5">
            <el-form-item prop="licence_img">
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                list-type="picture-card"
                :headers="{ token: token }"
                :http-request="httpRequestZj"
                accept="image/jpeg, image/png, image/gif"
              >
                <img v-if="this.form.data.licence_img" :src="this.form.data.licence_img" class="avatar">
                <i v-else class="el-icon-plus"></i>
                <div class="el-upload__tip tipTitle" slot="tip">
                  证件照
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item prop="medical_report_img">
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                list-type="picture-card"
                :headers="{ token: token }"
                :http-request="httpRequestTjbg"
                accept="image/jpeg, image/png, image/gif"
              >
                <img v-if="this.form.data.medical_report_img" :src="this.form.data.medical_report_img" class="avatar">
                <i v-else class="el-icon-plus"></i>
                <div class="el-upload__tip tipTitle" slot="tip">
                  体检报告
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item prop="bank_card_img">
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                list-type="picture-card"
                :headers="{ token: token }"
                :http-request="httpRequestYhk"
                accept="image/jpeg, image/png, image/gif"
              >
                <img v-if="this.form.data.bank_card_img" :src="this.form.data.bank_card_img" class="avatar">
                <i v-else class="el-icon-plus"></i>
                <div class="el-upload__tip tipTitle" slot="tip">
                  银行卡照片
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item prop="diploma">
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                list-type="picture-card"
                :headers="{ token: token }"
                :http-request="httpRequestByz"
                accept="image/jpeg, image/png, image/gif"
              >
                <img v-if="this.form.data.diploma" :src="this.form.data.diploma" class="avatar">
                <i v-else class="el-icon-plus"></i>
                <div class="el-upload__tip tipTitle" slot="tip">
                  毕业证
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="sign_image_url" v-if="!!form.data.sign_image_url">
              <div class="block">
                <el-image 
                style="transform: scale(1) rotate(-90deg);" 
                :src="this.form.data.sign_image_url" 
                class="avatar"
                :modal-append-to-body="true"
                @click="showImg"></el-image>
                <div class="el-upload__tip tipTitle" slot="tip">
                  个人签名
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-row type="flex" justify="center" style="margin-top:20px">
      <el-button @click='toggle(false)'>取消</el-button>
      <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
    </el-row>

    <!-- 图片预览 -->
    <el-image-viewer class="imageBox" v-if="showViewer" :on-close="closeViewer" :url-list="previewImages" />
  </div>
</template>

<script>
  import common from '@/util'
  import { getStorage } from '@/storage'
  // import { mapGetters } from 'vuex'
  import { REG_EXP } from '@/util'
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
  export default {
    name: 'StaffEdit',
    // computed: {
    //   ...mapGetters({
    //     companyOpt: 'companyList', // 获取企业列表
    //   })
    // },
    components: {
      ElImageViewer
    },
    data() {
      var checkRate = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入税率'));
        }
        if (!Number.isInteger(value)) {
          return callback(new Error('税率只能输入数字'));
        } else {
          callback();
        }
      };
      return {
        token: getStorage('token'),
        icId: Number(getStorage('icId')), // 登录账号的身份id（0：总公司）
        role_type: getStorage('role_type'), // 1:管理员，2：员工
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        showViewer: false,
        previewImages: [],
        form: {
          loading: false,
          data: {
            name: '',
            phone: '',
            sex: 1,
            entry_at: '',
            company_id: '',
            salary: '',
            is_insurance: 1,   // 五险
            insurance_level_id: '',
            insurance_pay_type: '',
            is_fund: 1,   // 公积金
            fund_level_id: '',
            fund_pay_type: '',
            is_duty: 1, // 雇主责任险
            company_duty_fee: '',
            person_duty_fee: '',
            id_card_portrait_face: '', // 身份证人像面
            id_card_emblem_face: '', // 身份证国徽面
            id_card_no: '',
            address: '',
            bank_card_no: '',
            bank_name: '',
            bank_card_name: '',
            mark: '',
            dimission_at: '',
            limit_bank_name: '',
            dimission_pay_type: '', // 当月薪资是否发放

            company_post_id: "", //岗位id
            contract_type: '', //合同类型：1-劳动合同；2-劳务合同；3-实习协议；4-兼职协议
            contract_date_type: '', //期限类型：1-固定期限；2-无固定期限；3-完成一定工作量；
            trial_day: [], // 试用期限
            trial_day_start: "", 
            trial_day_end: "", 
            contract_day: [], // 合同期限
            contract_day_start: "", 
            contract_day_end: "", 
            regular_job: "", // 正式工作单位

            email: "", //邮箱地址
            residence: "", //现居住地
            emergency_contact: "", //紧急联系人
            emergency_contact_relation: "", //紧急联系人关系
            emergency_contact_phone: "", //紧急联系人电话
            political_outlook: 1, //政治面貌：1-群众；2-团员；3-党员；
            marital_status: 1, //婚姻状况：1-未婚；2-已婚；3-离异
            nation: "", // 民族
            has_relative: 0, //是否有亲属在公司：0-无；1-有；
            relative_name: "", //亲属姓名
            relative_relation: "", //亲属关系
            relative_title: "", //亲属在公司职务
            has_endowment: 0, //是否缴纳养老保险
            has_medical: 0, //是否缴纳医疗保险
            has_unemployment: 0, //是否缴纳失业保险
            has_birth: 0, //是否缴纳生育保险
            has_injury: 0, //是否缴纳工伤保险
            has_fund: 0, //是否缴纳住房公积金
            licence_img: "", //一寸证件照地址
            medical_report_img: "", //体检报告照片
            bank_card_img: "", // 银行卡图片地址
            diploma: "", //毕业证
            sign_image_url: "", //签署确认照片
            is_sign_contract: 0, // 编辑时判断该员工是否签署合同：0-未签署；1-已签署

            
          },
          rules: {
            name: [{ required: true, message:'必填项', trigger: 'change'}],
            phone: [
              { required: true, message:'必填项', trigger: 'change'},
              {
                pattern: REG_EXP.mobilePhone,
                message: "手机号格式不正确",
                trigger: "change"
              }
            ],
            sex: [{ required: true, message:'必填项', trigger: 'change'}],
            entry_at: [{ required: true, message:'必填项', trigger: 'change'}],
            company_id: [{ required: true, message:'必填项', trigger: 'change'}],

            insurance_level_id:  [{ required: true, message:'必填项', trigger: 'change'}],
            insurance_pay_type:  [{ required: true, message:'必填项', trigger: 'change'}],
            fund_level_id:  [{ required: true, message:'必填项', trigger: 'change'}],
            fund_pay_type:  [{ required: true, message:'必填项', trigger: 'change'}],
            company_duty_fee:  [
              { required: true, message:'必填项', trigger: 'change'},
              {
                pattern: REG_EXP.money,
                message: "请填写正确的金额格式，如：188.88",
                trigger: "change",
              }
            ],
            person_duty_fee:  [
              { required: true, message:'必填项', trigger: 'change'},
              {
                pattern: REG_EXP.money,
                message: "请填写正确的金额格式，如：188.88",
                trigger: "change",
              }
            ],
            id_card_no: [
              { required: true, message: '必填项', trigger: 'change'},
              {
                pattern: REG_EXP.identityCard,
                message: "身份证格式不正确",
                trigger: "change",
              }
            ],
            address: [{ required: true, message:'必填项', trigger: 'change'}],
            bank_card_no: [{ required: true, message:'必填项', trigger: 'change'}],
            bank_name: [{ required: true, message:'必填项', trigger: 'change'}],
            bank_card_name: [{ required: true, message:'必填项', trigger: 'change'}],
            tax_point: [{ validator:checkRate, required: true, trigger: 'blur'}],
            dimission_at: [{ required: true, message:'必填项',trigger: 'blur'}],
            salary: [
              {
                pattern: REG_EXP.money,
                message: "请填写正确的金额格式，如：188.88",
                trigger: "change",
              }
            ],
            contract_type: [{ required: true, message:'必填项',trigger: 'blur'}],
            contract_date_type: [{ required: true, message:'必填项',trigger: 'blur'}],
            // trial_day: [{ required: true, message:'必填项',trigger: 'blur'}],
            contract_day: [{ required: true, message:'必填项',trigger: 'blur'}],
            regular_job: [{ required: true, message:'必填项',trigger: 'blur'}],

          }
        },
        // 教育信息
        educations: [
          {
            educations_date: [],
            start_date: '',
            end_date: '',
            school: '',
            content: '',
            licence: '',
          },
        ],
        // 工作经历
        jobs: [
          {
            jobs_date:[],
            start_date: '',
            end_date: '',
            company: '',
            job: '',
            salary: '',
            reason: '',
            contact: '',
          },
        ],
        // 家庭信息
        members:[
          {
            name: '',
            relation: '',
            age: '',
            address:'',
            phone: '',
          },
          {
            name: '',
            relation: '',
            age: '',
            address:'',
            phone: '',
          }
        ],
        // 缴纳方式
        payType: [
          {
            value: 1,
            label: '单位全额'
          },
          {
            value: 2,
            label: '员工全额'
          },
          {
            value: 3,
            label: '单位员工各自缴纳'
          }
        ],
    
        // 雇主责任险、大病险缴纳方式
        payTypeS: [
          {
            value: 1,
            label: '单位全额'
          },
          {
            value: 2,
            label: '员工全额'
          }
        ],
        InsuranceLevelOpt: [],
        fundLevelOpt: [],
        isChecked: true,
        isbankShow: false,
        jobsArr:[], //岗位
        limit_banks: [],// 开户银行

        bankType: [], // 银行卡
        companyOpt: [], //用工单位
      }
    },
    created() {
      this.getInsurance();
      this.getFind();
      this.getBank();
      this.getCompany();
    },
    methods: {
      getCompany() {
        //获取企业列表
        this.$http.get('/admin/company/searchList',{params:{}}).then(res => {
          if(res.code) {
            this.companyOpt = res.data;
          } else {
            this.$message.eroor(res.msg)
          }
        })
      },
      // 获取银行卡
      getBank() {
        this.$http.get('/admin/config/banks', {params:{}}).then(res => {
          if(res.code ===1) {
            this.bankType = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      // 获取五险
      getInsurance() {
        this.$http.post('/admin/InsuranceLevel/list', {is_all: 1}).then(res => {
          if(res.code === 1) {
            this.InsuranceLevelOpt = res.data.list;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      // 获取公积金
      getFind() {
        this.$http.post('/admin/fundLevel/list', {is_all: 1}).then(res => {
          if(res.code === 1) {
            this.fundLevelOpt = res.data.list;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      // 获取员工详情
      getDetail(row) {
        this.isChange = true
        this.$http.post('/admin/employee/detail', { id: row.id }).then(res => {
          if(res.code === 1) {
            if(res.data.name) {
              common.deepClone(this.form.data, res.data)
              this.getJobs(res.data.company_id) // 根据企业查询岗位
              this.limit_banks = res.data.limit_banks; // 获取开户银行卡

              // 岗位、合同类型、期限类型，如果返回0则清空值
              this.form.data.company_post_id = res.data.company_post_id == 0 ? '' : res.data.company_post_id;
              this.form.data.contract_type = res.data.contract_type == 0 ? '' : res.data.contract_type;
              this.form.data.contract_date_type = res.data.contract_date_type == 0 ? '' : res.data.contract_date_type;

              if(this.form.data.bank_name != this.form.data.limit_bank_name) {
                this.isbankShow = false;
              } else if(this.form.data.limit_bank_id === 0) {
                this.isbankShow = false;
              } else if (this.form.data.bank_name === this.form.data.limit_bank_name) {
                this.isbankShow = true;
              }
              this.form.data.trial_day = [res.data.trial_day_start,res.data.trial_day_end]
              this.form.data.contract_day = [res.data.contract_day_start,res.data.contract_day_end]

              if(res.data.members.length > 0) {
                this.members = res.data.members;
              }
              // 教育信息
              if(res.data.educations.length > 0) {
                this.educations = res.data.educations.map(v => {
                  return {
                    educations_date: [v.start_date,v.end_date],
                    school: v.school,
                    content: v.content,
                    licence: v.licence,
                  }
                })
              }
              // 工作经历
              if(res.data.jobs.length > 0) {
                this.jobs = res.data.jobs.map(j => {
                  return {
                    jobs_date: [j.start_date,j.end_date],
                    company: j.company,
                    job: j.job,
                    salary: j.salary,
                    reason: j.reason,
                    contact: j.contact,
                  }
                })
              }

              
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$nextTick(() => {
          this.$refs.elFormDom.resetFields(); // 重置表单到初始值
        });
        this.form.data.is_sign_contract = 0;
        this.form.data.company_post_id = '';
        this.form.data.contract_type = '';
        this.form.data.contract_date_type = '';
        this.form.data.trial_day = [];
        this.form.data.contract_day = [];
        this.form.data.regular_job = '';
        this.form.data.sign_image_url = '';
        this.form.data.diploma = '';
        this.form.data.insurance_level_id = '';
        this.form.data.insurance_pay_type = '';
        this.form.data.fund_level_id = '';
        this.form.data.fund_pay_type = '';
        this.form.data.company_duty_fee = '';
        this.form.data.person_duty_fee = '';
        // 教育信息清空
        this.educations= [
          {
            educations_date: [],
            start_date: '',
            end_date: '',
            school: '',
            content: '',
            licence: '',
          }
        ],
        // 工作经历清空
        this.jobs= [
          {
            jobs_date: [],
            start_date: '',
            end_date: '',
            company: '',
            job: '',
            salary: '',
            reason: '',
            contact: '',
          }
        ],
        // 家庭信息清空
        this.members=[
          {
            name: '',
            relation: '',
            age: '',
            address:'',
            phone: '',
          },
          {
            name: '',
            relation: '',
            age: '',
            address:'',
            phone: '',
          }
        ],

        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        console.log(this.form.data)
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            // 在职状态时参数剔除掉离职时间
            if(this.form.data.status === 1 ) {
              delete this.form.data.dimission_at
              delete this.form.data.dimission_type
            }
            // 勾选了无银行卡时银行卡信息清空
            if(this.isChecked === false) {
              this.form.data.bank_card_name = ''
              this.form.data.bank_card_no = ''
              this.form.data.bank_name = ''
            }
            if(this.form.data.company) {
             delete this.form.data.company
            }
            if(this.form.data.fund) {
              delete this.form.data.fund
            }
            if(this.form.data.insurance) {
              delete this.form.data.insurance
            }
            delete this.form.data.created_at
            delete this.form.data.updated_at
            
            // 开户银行不可编辑
            this.form.data.bank_name = this.isChecked == false ? '' : this.form.data.bank_name
            // 编辑提交时判断员工开户行和所在企业限定的开户行是否一直
            if(this.isChange === true && this.isChecked === true) {
              if(this.form.data.limit_bank_name != '' && this.form.data.bank_name != this.form.data.limit_bank_name) {
                this.$message.warning(`企业已限定${this.form.data.limit_bank_name},请修改后再提交`)
                return
              }
            }
            // 试用期限
            if(this.form.data.contract_type == 1 || this.form.data.contract_type == 2) {
              let _trial_day =  this.form.data.trial_day;
              this.form.data.trial_day_start = !!_trial_day ? _trial_day[0] : '';
              this.form.data.trial_day_end = !!_trial_day ? _trial_day[1] : '';
            }
            // 合同期限
            let _contract_day =  this.form.data.contract_day;
            this.form.data.contract_day_start = !!_contract_day ? _contract_day[0] : '';
            this.form.data.contract_day_end = !!_contract_day ? _contract_day[1] : '';
            // 教育信息
            if(this.educations.length == 1  ) {
              let _educations = []
              console.log(this.educations)
              console.log(this.educations[0].educations_date)
              if(this.educations[0].educations_date != null && this.educations[0].educations_date != '' && this.educations[0].educations_date.length > 0) {
                _educations[0] =  {
                    start_date: this.educations[0].educations_date[0] || '',
                    end_date: this.educations[0].educations_date[1] || '',
                    school: this.educations[0].school,
                    content: this.educations[0].content,
                    licence: this.educations[0].licence,
                  }
              } else {
                _educations[0] = {
                  start_date: '',
                  end_date: '',
                  school: '',
                  content: '',
                  licence: '',
                }
              }
              this.form.data.educations = _educations;
            } else if(this.educations.length == 2) {
              let _educations = []
              if(this.educations[0].educations_date != null && this.educations[0].educations_date != '' && this.educations[0].educations_date.length > 0 ) {
                _educations[0] =  {
                    start_date: this.educations[0].educations_date[0] || '',
                    end_date: this.educations[0].educations_date[1] || '',
                    school: this.educations[0].school,
                    content: this.educations[0].content,
                    licence: this.educations[0].licence,
                  }
              } else {
                _educations[0] = {
                  start_date: '',
                  end_date: '',
                  school: '',
                  content: '',
                  licence: '',
                }
              }
              
              if(this.educations[1].educations_date != null && this.educations[1].educations_date.length > 0) {
                _educations[1] = {
                    start_date: this.educations[1].educations_date[0] || '',
                    end_date: this.educations[1].educations_date[1] || '',
                    school: this.educations[1].school,
                    content: this.educations[1].content,
                    licence: this.educations[1].licence,
                  }
              } else {
                _educations[1] = {
                  start_date: '',
                  end_date: '',
                  school: '',
                  content: '',
                  licence: '',
                }
              }
              this.form.data.educations = _educations;
            } else {
              this.form.data.educations = [];
            }
           
            // 工作经历
            if(this.jobs.length == 1 && !!this.jobs[0].jobs_date) {
              let _jobs = []
              if(this.jobs[0].jobs_date != null && this.jobs[0].jobs_date.length > 0) {
                _jobs[0] =  {
                    start_date: this.jobs[0].jobs_date[0] || '',
                    end_date: this.jobs[0].jobs_date[1] || '',
                    company: this.jobs[0].company,
                    job: this.jobs[0].job,
                    salary: this.jobs[0].salary,
                    reason: this.jobs[0].reason,
                    contact: this.jobs[0].contact,
                  }
              } else {
                _jobs[0] = {
                  start_date: '',
                  end_date: '',
                  company: '',
                  job: '',
                  salary: '',
                  reason: '',
                  contact: '',
                }
              }
              this.form.data.jobs = _jobs;
            } else if(this.jobs.length == 2 && !!this.jobs[0].jobs_date) {
              let _jobs = []
              if(this.jobs[0].jobs_date != null && this.jobs[0].jobs_date.length > 0) {
                _jobs[0] =  {
                    start_date: this.jobs[0].jobs_date[0] || '',
                    end_date: this.jobs[0].jobs_date[1] || '',
                    company: this.jobs[0].company,
                    job: this.jobs[0].job,
                    salary: this.jobs[0].salary,
                    reason: this.jobs[0].reason,
                    contact: this.jobs[0].contact,
                  }
              } else {
                _jobs[0] = {
                  start_date: '',
                  end_date: '',
                  company: '',
                  job: '',
                  salary: '',
                  reason: '',
                  contact: '',
                }
              }
              if(this.jobs[1].jobs_date != null && this.jobs[1].jobs_date.length > 0) {
                _jobs[1] =  {
                    start_date: this.jobs[1].jobs_date[0] || '',
                    end_date: this.jobs[1].jobs_date[1] || '',
                    company: this.jobs[1].company,
                    job: this.jobs[1].job,
                    salary: this.jobs[1].salary,
                    reason: this.jobs[1].reason,
                    contact: this.jobs[1].contact,
                  }
              } else {
                _jobs[1] = {
                  start_date: '',
                  end_date: '',
                  company: '',
                  job: '',
                  salary: '',
                  reason: '',
                  contact: '',
                }
              }
              this.form.data.jobs = _jobs;
            } else {
              this.form.data.jobs = [];
            }
            // 家庭信息
            if(this.members.length > 0) {
              this.form.data.members = this.members;
            } else {
              this.form.data.members = [];
            }
            // 是否有亲属在公司 0:无，1：有
            if(this.form.data.has_relative == 0) {
              this.form.data.relative_name = '';
              this.form.data.relative_relation = '';
              this.form.data.relative_title = '';
            }
            // 判断如果岗位没选，则合同信息清空
            if(this.form.data.company_post_id == 0 || !this.form.data.company_post_id) {
              this.form.data.contract_type = '';
              this.form.data.contract_date_type = '';
              this.form.data.contract_day_start = '';
              this.form.data.contract_day_end = '';
              this.form.data.trial_day_start = '';
              this.form.data.trial_day_end = '';
            }
            let apiUrl = !this.isChange ? '/admin/employee/create' : '/admin/employee/edit'
            this.form.loading = true;
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              }else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      handleCompanyChange(id) {
        this.companyfilter(id); 
        this.getJobs(id)
      },
      // 根据企业id带出企业福利
      companyfilter(id) {
        let arr =  this.companyOpt.filter(item => {
          return item.id == id;
        })
        console.log(arr)
        console.log(arr[0].limit_banks)
        console.log(arr[0].limit_banks.length)
        this.limit_banks = [];
        if(arr[0].limit_banks.length > 1) {
          this.limit_banks = arr[0].limit_banks;
          this.form.data.bank_name = arr[0].limit_bank_name;
        } else {
          this.form.data.bank_name = arr[0].limit_bank_name;
        }
        if(this.isChange != true) {
          this.form.data.bank_name = arr[0].limit_bank_name; // 新增时限制开户行名称根据企业带出
        }
        this.form.data.limit_bank_name = arr[0].limit_bank_name;
        if(this.form.data.limit_bank_name === '' || this.form.data.limit_bank_name != this.form.data.bank_name) {
          this.isbankShow = false;
        }
        this.form.data.salary = arr[0].salary; // 基本工资
      },
      // 根据企业查询企业的岗位
      getJobs(companyId) {
        this.$http.get('/admin/Company/searchPost', {params:{id:companyId}}).then(res => {
          if(res.code === 1) {
            this.jobsArr = res.data;
          }
        })
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isLt5M;
      },
      // 身份证人像面
      httpRequestFront(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
          if(res.code === 1) {
            this.form.data.id_card_portrait_face = res.data.all_path_url;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      // 身份证国徽面
      httpRequestBack(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
          if(res.code === 1) {
            this.form.data.id_card_emblem_face = res.data.all_path_url;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      // 一寸证件照
      httpRequestZj(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
          if(res.code === 1) {
            this.form.data.licence_img = res.data.all_path_url;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      httpRequestTjbg(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
          if(res.code === 1) {
            this.form.data.medical_report_img = res.data.all_path_url;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      httpRequestYhk(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
          if(res.code === 1) {
            this.form.data.bank_card_img = res.data.all_path_url;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      httpRequestByz(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
          if(res.code === 1) {
            this.form.data.diploma = res.data.all_path_url;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      handleChangeRodio(type, event) {
        if(type === 'insurance') {
          this.form.data.insurance_level_id = ''
          this.form.data.insurance_pay_type = ''
        }
        if(type === 'fund') {
          this.form.data.fund_level_id = ''
          this.form.data.fund_pay_type = ''
        }
        if(type === 'duty') {
          this.form.data.company_duty_fee = ''
          this.form.data.person_duty_fee = ''
        }
      },
      // 新增时输入员工姓名和身份证号若员工有完善过所有信息，则信息自动带出来
      getUserInfo() {
        if(!!this.form.data.name && !!this.form.data.id_card_no) {
          let _params = {
            name:this.form.data.name, 
            id_card_no: this.form.data.id_card_no, 
          }
          this.$http.get('/admin/employee/user', {params: _params}).then(res => {
            if(res.code == 1) {
              if(!!res.data.name) {
                common.deepClone(this.form.data, res.data)
                this.getJobs(res.data.company_id) // 根据企业查询岗位
                if(this.form.data.bank_name != this.form.data.limit_bank_name) {
                  this.isbankShow = false;
                } else if(this.form.data.limit_bank_id === 0) {
                  this.isbankShow = false;
                } else if (this.form.data.bank_name === this.form.data.limit_bank_name) {
                  this.isbankShow = true;
                }
                this.form.data.trial_day = [res.data.trial_day_start,res.data.trial_day_end]
                this.form.data.contract_day = [res.data.contract_day_start,res.data.contract_day_end]

                if(!!res.data.members) {
                  this.members = res.data.members;
                }
                // 教育信息
                if(res.data.educations.length > 0) {
                  this.educations = res.data.educations.map(v => {
                    return {
                      educations_date: [v.start_date,v.end_date],
                      school: v.school,
                      content: v.content,
                      licence: v.licence,
                    }
                  })
                }
                // 工作经历
                if(res.data.jobs.length > 0) {
                  this.jobs = res.data.jobs.map(j => {
                    return {
                      jobs_date: [j.start_date,j.end_date],
                      company: j.company,
                      job: j.job,
                      salary: j.salary,
                      reason: j.reason,
                      contact: j.contact,
                    }
                  })
                }
              } 
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      },

      // 查看图片
      showImg() {
        if (this.form.data.sign_image_url) {
          this.showViewer = true;
          this.previewImages = [this.form.data.sign_image_url];
        } else {
          this.$message({
            type: "error",
            message: "图片不存在!",
          });
        }
      },
      // 关闭图片
      closeViewer() {
        this.showViewer = false;
        this.previewImages = [];
      },
      // 教育经历
      educationsPlus() {
        if(this.educations.length > 1) {
          return
        } else {
          let str = {
            educations_date: [],
            start_date: '',
            end_date: '',
            school: '',
            content: '',
            licence: '',
          }
          this.educations.push(str)
        }
      },
      // 教育经历
      educationsDel(index) {
        if(this.educations.length < 1) {
          return
        } else {
          this.educations.splice(index,1)
        }
      },

      jobsPlus() {
        if(this.jobs.length > 1) {
          return
        } else {
          let str = {
            jobs_date:[],
            start_date: '',
            end_date: '',
            company: '',
            job: '',
            salary: '',
            reason: '',
            contact: '',
          }
          this.jobs.push(str)
        }
      },
      jobsDel(index) {
        if(this.jobs.length < 1) {
          return
        } else {
          this.jobs.splice(index,1)
        }
      },
     
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #fff;
  margin: 10px;
  z-index: 5 !important;
  overflow: scroll;
  
  .main {
    margin: 0 auto;
    // background: #fbfbfb;

    .staff-from {
      width: 80%;
      margin: 0 auto;
    }
  }
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 148px;
    height: 148px;
    display: block;
    // width: 100%;
  }

  .idPhoto ::v-deep .avatar{
    width: 260px !important;
    height: 170px !important;
    line-height: 170px !important;
  }
  .idPhoto ::v-deep .avatar-uploader-icon {
    width: 260px !important;
    height: 170px !important;
    line-height: 170px !important;
    border: 1px dashed #d9d9d9;
  }
  .onBtn-large {
    display: flex;
    justify-content: center;
  }
  .rowbg {
    background: #fbfbfb;
    padding: 0 10px;
  }
  .width100 {
    width: 100%;
  }
  .tipTitle {
    width: 148px;
    text-align: center;
  }
  .icon-btn {
    margin-top: 15px;
    text-align: right;
  }
  // .divider-title:before {
  //   content: "*";
  //   color: #f56c6c;
  //   margin-right: 4px;
  // }
</style>
<style >
.imageBox .el-image-viewer__canvas {
  transform: rotate(-90deg);
}
</style>
