<template>
  <el-dialog
    width="900px"
    title="查看"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="tableWrap">
      <el-divider content-position="left">员工信息</el-divider>
      <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
        <tbody>
          <tr>
            <td class="des" style="width:120px;">名称</td>
            <td>{{ detailInfo.name }}</td>
            <td colspan="4" class="des" style="width:120px;">性别</td>
            <td>{{ detailInfo.sex === 1 ? '男' : '女' }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">电话</td>
            <td>{{ detailInfo.phone }}</td>
            <td colspan="4" class="des" style="width:120px;">入职时间</td>
            <td>{{ detailInfo.entry_at }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">用工单位</td>
            <td><div v-if="!!detailInfo.company">{{ detailInfo.company.name}}</div></td>
            <td colspan="4" class="des" style="width:120px;">基本工资</td>
            <td>{{ detailInfo.salary }}元</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">状态</td>
            <td>{{ detailInfo.status === 1 ? '在职' : '离职' }}</td>
            <td colspan="4" class="des" style="width:120px;">离职时间</td>
            <td>
              <template v-if="detailInfo.status == 2">
                <span>{{ detailInfo.status === 2 ? detailInfo.dimission_at : '' }}</span>
                <span style="margin-left:10px">{{toStr({1:'(当月正常发放)',2:'(当月无需发放)'},detailInfo.dimission_pay_type)}}</span>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
      <el-divider content-position="left">保险信息</el-divider>
      <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
        <tbody>
          <tr>
            <td class="des">缴纳五险</td>
            <td colspan="2">{{ detailInfo.is_insurance === 1 ? '是' : '否' }}</td>
            <td class="des">选择档次</td>
            <td colspan="2"><span v-if="detailInfo.is_insurance === 1 && detailInfo.insurance">{{ detailInfo.insurance.name || '' }}</span></td>
            <td class="des">缴纳方式</td>
            <td colspan="3">{{ payType[detailInfo.insurance_pay_type] || ''}}</td>
          </tr>
          <tr>
            <td class="des">缴纳公积金</td>
            <td colspan="2">{{ detailInfo.is_fund === 1 ? '是' : '否' }}</td>
            <td class="des">缴纳基数</td>
            <td colspan="2"><span v-if="detailInfo.is_fund === 1 && detailInfo.fund">{{ detailInfo.fund.base || ''}}元;{{detailInfo.fund.company_rate || ''}}%,{{detailInfo.fund.employees_rate || ''}}%</span></td>
            <td class="des">缴纳方式</td>
            <td colspan="3"><span v-if="detailInfo.is_fund === 1">{{ payType[detailInfo.fund_pay_type] || '' }}</span></td>
          </tr>
          <tr>
            <td class="des">雇主责任险</td>
            <td colspan="2">{{ detailInfo.is_duty === 1 ? '是' : '否' }}</td>
            <td class="des">单位部分</td>
            <td colspan="2">{{detailInfo.company_duty_fee}}元</td>
            <td class="des">员工部分</td>
            <td colspan="3">{{detailInfo.person_duty_fee}}元</td>
          </tr>
          <!-- <tr>
            <td class="des">大病医疗险</td>
            <td colspan="2">{{ detailInfo.is_illness === 1 ? '是' : '否' }}</td>
            <td class="des">单位部分</td>
            <td colspan="2">{{detailInfo.company_illness_fee}}元</td>
            <td class="des">员工部分</td>
            <td colspan="3">{{detailInfo.person_illness_fee}}元</td>
          </tr> -->
        </tbody>
      </table>

      <!-- "is_sign_contract", 是否签署合同：0-未签署；1-已签署；若未签署可以查看，已签署则不展示岗位及合同信息 -->
      <el-descriptions title="" :column="2" border style="margin-top:10px" v-if="detailInfo.is_sign_contract == 0">
        <el-descriptions-item label="所在岗位">{{detailInfo.company_post_name}}</el-descriptions-item>
        <el-descriptions-item label="合同类型">{{toStr({1:'劳动合同',2:'劳务合同',3:'实习协议',4:'兼职协议'},detailInfo.contract_type)}}</el-descriptions-item>
        <!-- 只有选劳动合同才有期限类型这个字段 -->
        <el-descriptions-item label="期限类型" v-if="detailInfo.contract_type == 1">{{toStr({1:'固定期限',2:'无固定期限',3:'完成一定工作量'},detailInfo.contract_date_type)}}</el-descriptions-item>
        <!-- 劳动或劳务合同才有试用期限 -->
        <el-descriptions-item label="试用期限" v-if="detailInfo.contract_type == 1 || detailInfo.contract_type == 2">{{detailInfo.trial_day_start}}-{{detailInfo.trial_day_end}}</el-descriptions-item>
        <!-- 兼职协议有正式工作单位 -->
        <el-descriptions-item label="正式工作单位" v-if="detailInfo.contract_type == 4">{{detailInfo.regular_job}}</el-descriptions-item>
        <el-descriptions-item label="合同期限">{{detailInfo.contract_day_start}}-{{detailInfo.contract_day_end}}</el-descriptions-item>
      </el-descriptions>

      <el-divider content-position="left">身份证信息</el-divider>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-image fil="cover" style="width: 260px; height: 170px" :src="detailInfo.id_card_portrait_face"></el-image>
        </el-col>
        <el-col :span="12">
          <el-image fil="cover" style="width: 260px; height: 170px" :src="detailInfo.id_card_emblem_face"></el-image>
        </el-col>
      </el-row>
      <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
        <tbody>
          <tr>
            <td class="des" style="width:120px;">身份证号</td>
            <td>{{ detailInfo.id_card_no }}</td>
            <td colspan="4" class="des" style="width:120px;">户籍地址</td>
            <td>{{ detailInfo.address }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">现居住地</td>
            <td>{{ detailInfo.residence }}</td>
            <td colspan="4" class="des" style="width:120px;">电子邮箱</td>
            <td>{{ detailInfo.email }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">紧急联系人</td>
            <td>{{ detailInfo.emergency_contact }}</td>
            <td colspan="4" class="des" style="width:120px;">联系人电话</td>
            <td>{{ detailInfo.emergency_contact_phone }}</td> 
          </tr>
          <tr>
            <td class="des" style="width:120px;">关系</td>
            <td>{{ detailInfo.emergency_contact_relation }}</td>
            <td colspan="4" class="des" style="width:120px;">民族</td>
            <td>{{ detailInfo.nation }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">政治面貌</td>
            <td>{{ toStr({1:'群众',2:'团员',3:'党员'},detailInfo.political_outlook) }}</td>
            <td colspan="4" class="des" style="width:120px;">婚姻状况</td>
            <td>{{ toStr({1:'未婚',2:'已婚',3:'离异'},detailInfo.marital_status) }}</td>
          </tr>
        </tbody>
      </table>
      <el-divider content-position="left">银行卡信息</el-divider>
      <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
        <tbody>
          <tr>
            <td class="des" style="width:120px;">银行卡号</td>
            <td>{{ detailInfo.bank_card_no }}</td>
            <td colspan="4" class="des" style="width:120px;">开户银行</td>
            <td>{{ detailInfo.bank_name }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">持卡人</td>
            <td>{{ detailInfo.bank_card_name }}</td>
            <td colspan="4" class="des" style="width:120px;"></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <p>备注： {{detailInfo.mark}}</p>
      <template v-if="detailInfo.educations.length > 0">
        <el-divider content-position="left">教育信息（从最高学历开始填写）</el-divider>
        <el-descriptions title="" :column="2" border style="margin-top:10px" v-for="(item1, index1) in detailInfo.educations" :key="index1">
          <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="起止时间">{{item1.start_date}}-{{item1.end_date}}</el-descriptions-item>
          <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="学校/培训经历">{{item1.school}}</el-descriptions-item>
          <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="专业/培训内容">{{item1.content}}</el-descriptions-item>
          <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="取得学历/证书">{{item1.licence}}</el-descriptions-item>
        </el-descriptions>
      </template>

      <template v-if="detailInfo.jobs.length > 0">
        <el-divider content-position="left">工作经历（从第一份工作开始填写）</el-divider>
        <el-descriptions title="" :column="2" border style="margin-top:10px" v-for="(item2, index2) in detailInfo.jobs" :key="index2">
          <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="起止时间">{{item2.start_date}}-{{item2.end_date}}</el-descriptions-item>
          <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="工作单位">{{item2.company}}</el-descriptions-item>
          <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="工作岗位">{{item2.job}}</el-descriptions-item>
          <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="薪资水平">{{item2.salary}}</el-descriptions-item>
          <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="离职原因">{{item2.reason}}</el-descriptions-item>
          <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="证明人/电话">{{item2.contact}}</el-descriptions-item>
        </el-descriptions>
      </template>

      <template v-if="detailInfo.members.length > 0">
        <el-divider content-position="left">家庭信息（父母、配偶、子女、兄弟姐妹）</el-divider>
        <el-descriptions title="" :column="2" border style="margin-top:10px" v-for="(item3, index3) in detailInfo.members" :key="index3">
          <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="姓名">{{item3.name}}</el-descriptions-item>
          <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="与本人关系">{{item3.relation}}</el-descriptions-item>
          <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="年龄">{{item3.age}}</el-descriptions-item>
          <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="现居地址/工作单位">{{item3.address}}</el-descriptions-item>
          <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="电话">{{item3.phone}}</el-descriptions-item>
        </el-descriptions>
      </template>

      <p>是否有亲属在公司： {{detailInfo.has_relative == 0 ? '无' : '有'}}</p>
      <el-descriptions title="" :column="3" border style="margin-top:10px" v-if="detailInfo.has_relative == 1" >
        <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="姓名">{{detailInfo.relative_name}}</el-descriptions-item>
        <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="关系">{{detailInfo.relative_relation}}</el-descriptions-item>
        <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="职务">{{detailInfo.relative_title}}</el-descriptions-item>
      </el-descriptions>

      <el-divider content-position="left">福利保险</el-divider>
      <el-descriptions title="" :column="2" border style="margin-top:10px" >
        <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="养老保险">{{detailInfo.has_endowment == 0 ? '无' : '有'}}</el-descriptions-item>
        <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="医疗保险">{{detailInfo.has_medical == 0 ? '无' : '有'}}</el-descriptions-item>
        <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="失业保险">{{detailInfo.has_unemployment == 0 ? '无' : '有'}}</el-descriptions-item>
        <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="生育保险">{{detailInfo.has_birth == 0 ? '无' : '有'}}</el-descriptions-item>
        <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="工伤保险">{{detailInfo.has_injury == 0 ? '无' : '有'}}</el-descriptions-item>
        <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width: '200px'}" label="住房公积金">{{detailInfo.has_fund == 0 ? '无' : '有'}}</el-descriptions-item>
      </el-descriptions>

      <el-row style="margin-top:10px">
        <el-col :span="6" v-if="detailInfo.licence_img">
          <el-image
            style="width: 100px; height: 100px"
            :src="detailInfo.licence_img"
            fit="cover"
            :preview-src-list="[detailInfo.licence_img]"></el-image>
          <div style="width:100px;text-align:center;">
            证件照
          </div>
        </el-col>
        <el-col :span="6" v-if="detailInfo.medical_report_img">
          <el-image
            style="width: 100px; height: 100px"
            :src="detailInfo.medical_report_img"
            fit="cover"
            :preview-src-list="[detailInfo.medical_report_img]"></el-image>
          <div style="width:100px;text-align:center;">
            体检报告
          </div>
        </el-col>
        <el-col :span="6" v-if="detailInfo.bank_card_img">
          <el-image
            style="width: 100px; height: 100px"
            :src="detailInfo.bank_card_img"
            fit="cover"
            :preview-src-list="[detailInfo.bank_card_img]"></el-image>
          <div style="width:100px;text-align:center;">
            银行卡照片
          </div>
        </el-col>
        <el-col :span="6" v-if="detailInfo.sign_image_url">
          <el-image 
            style="width: 100px; height: 100px; transform: scale(1) rotate(-90deg); "
            :src="this.detailInfo.sign_image_url" 
            class="avatar"
            :append-to-body="true"
            @click="showImg"></el-image>
          <div style="width:100px;text-align:center;">
            个人签名
          </div>
        </el-col>
      </el-row>

      <!-- 图片预览 -->
      <el-image-viewer class="imageBox" v-if="showViewer" :on-close="closeViewer" :url-list="previewImages" />

    </diV> 
  </el-dialog>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import common from '@/util'
  export default {
    name: 'StaffDetail',
    components: {
      ElImageViewer
    },
    data() {
      return {
        visible: false,
        detailInfo: {
          name: '',
          company: {},
          insurance: {
            name: '',
          },
          fund: {},
          educations: [],
          jobs:[],
          members: [],
        },
        managerFeeType: {
          1:'企业',
          2:'员工'
        },
        settlementType: {
          1:'当月薪资',
          2:'上月薪资'
        },
        payType: {
          1: '单位全额',
          2: '员工全额',
          3: '单位员工各自缴纳'
        },
        showViewer: false,
        previewImages: [],
      }
    },
    created() {
      this.getDetail();
    },
    methods: {
      // 获取员工详情
      getDetail(employeeId) {
        this.detailInfo = {
          name: '',
          company: {},
          insurance: {
            name: '',
          },
          fund: {},
          educations: [],
          jobs:[],
          members: [],
        };
        if(!!employeeId) {
          this.$http.post('/admin/employee/detail', { id: employeeId }).then(res => {
            if(res.code === 1) {
              // common.deepClone(this.detailInfo, res.data)
              this.detailInfo = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      toStr(data, key) {
        return data[key]
      },
      // 查看图片
      showImg() {
        if (this.detailInfo.sign_image_url) {
          this.showViewer = true;
          this.previewImages = [this.detailInfo.sign_image_url];
        } else {
          this.$message({
            type: "error",
            message: "图片不存在!",
          });
        }
      },
      // 关闭图片
      closeViewer() {
        this.showViewer = false;
        this.previewImages = [];
      },
    }
  }
</script>
<style scoped lang="scss">
// 详情自定义table表格样式
.tableWrap .list {
  padding: 0 12px;
  margin-bottom: 30px;
}
 .tableWrap table {
  width: 100%;
  border-collapse: collapse;
}
 .tableWrap table td{
  height: 30px;
  line-height: 22px;
  padding: 5px;
  border: 1px solid #ddd;
}
.tableWrap .des {
  background: #fbfbfb;
   width: 90px;
}
.tableWrap .d-title {
  height: 80px;
  line-height: 80px;
}

</style>
<style>
.imageBox .el-image-viewer__canvas {
  transform: rotate(-90deg);
}
.imageBox.el-image-viewer__wrapper {
  z-index: 3000 !important
}
</style>
